import React, { useEffect, useState } from "react";
// import chart from "../assests/images/icon-chart.svg";
import bnbIcon from "../assests/images/icon-bnb.svg";
import pinksaleIcon from "../assests/images/icon-pinksale.svg";
import coinmarketcapIcon from "../assests/images/icon-coinmarketcap.svg";
import dexview_logo from "../assests/images/dexview_logo.png";
import pancakeswapIcon from "../assests/images/icon-pancakeswap.svg";
import coinsultIcon from "../assests/images/icon-coinsult.svg";
import aiImg1 from "../assests/images/heading1.png";
import aiImg2 from "../assests/images/heading2.png";
import aiImg4 from "../assests/images/icon-ai-img4.svg";
import roadmap from "../assests/images/roadmap.svg";
import roadmapmob from "../assests/images/Group 8.svg";
// import sparkleIcon1 from "../assests/images/icon-sparkle1.svg";
import sparkleIcon2 from "../assests/images/icon-sparkle2.svg";
import sparkleIcon3 from "../assests/images/icon-sparkle3.svg";
import sparkleIcon4 from "../assests/images/icon-sparkle4.svg";
import sparkleIcon5 from "../assests/images/icon-sparkle5.svg";
import AOS from "aos";
import "aos/dist/aos.css";

import "./style.css";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import CircularProgressBar from "../CircularProgressBar";
import { coinsultlink, dexviewlink, pancakeswaplink, pinksalelink, tokenAddress } from "../sociallinks";

export default function Home() {
  let [isMobile, setisMobile] = useState(false);
  const [iscopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(tokenAddress);
      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  function redirectToNewTab(url) {
    window.open(url, "_blank");
  }

  useEffect(() => {
    setisMobile(window.innerWidth < 800);
    AOS.init({ duration: 2000 });
  }, []);
  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <img src={bnbIcon} className="bnbIcon" alt="binance-icon" onDragStart={handleDragStart} />,
    <img src={pinksaleIcon} onClick={() => redirectToNewTab(pinksalelink)} alt="pinksale-icon" onDragStart={handleDragStart} />,
    <img src={coinmarketcapIcon} className="coinmarketcapIcon" alt="coinmarketcap-icon" onDragStart={handleDragStart} />,
    <img src={pancakeswapIcon} onClick={() => redirectToNewTab(pancakeswaplink)} className="pancakeswapIcon" alt="pancakeswap-icon" onDragStart={handleDragStart} />,
    <img src={dexview_logo} onClick={() => redirectToNewTab(dexviewlink)} className="dexviewIcon" alt="dexview-icon" onDragStart={handleDragStart} />,
    <img src={coinsultIcon} onClick={() => redirectToNewTab(coinsultlink)} className="coinsultIcon" alt="coinsult-icon" onDragStart={handleDragStart} />,
  ];

  const responsive = {
    0: { items: 1 },
    600: { items: 2 },
    1200: { items: 3 },
    1500: { items: 5 },
  };

  return (
    <>
      <div className="backdropDiv1">
        <div className="eclipseDiv"></div>
        <div id="home" className="mainWrapper1 faders" data-aos="fade-up">
          <div className="titleContent">
            <div>
              <div className="mainHeadingTxt">
                Welcome to
                <br />
                <div className="gradientTxt">BabyYooshi</div>
              </div>
            </div>
            <div className="titleInfo">Meet the newest member of the grand YooshiApe family, Baby YooshiApe! This unique token has been designed to empower the YooshiApe community even further. We're offering special benefits for every investor holding Baby YooshiApe.</div>
            <div className="btn1" onClick={() => redirectToNewTab(dexviewlink)}>
              DEXVIEW
            </div>
            <div className="tokenomics-box address-box">
              <>
                <div className="copy">Token Address :{iscopied ? <i className="fas fa-check"></i> : <i onClick={handleCopyClick} className="far fa-copy"></i>}</div>
                <div className="address button-text">
                  <span>{tokenAddress}</span>
                </div>
              </>
            </div>
          </div>
          <img src={aiImg1} alt="ai-img" className="float-img" />
          <img className="aiImg4" src={aiImg4} alt="ai-img" />
        </div>
      </div>

      <div className="backdropDiv2">
        <div className="mainWrapper2 faders">
          <div className="binanceDiv">
            <AliceCarousel items={items} responsive={responsive} autoPlay autoPlayInterval={1000} animationDuration={1000} disableButtonsControls disableDotsControls mouseTracking infinite animationType="fadeout" paddingLeft={24} paddingRight={24} itemPosition="center" stagePadding={{ paddingLeft: 60, paddingRight: 60 }} slideToIndex={0} dotsDisabled buttonsDisabled />
          </div>
          <div id="about" className="aboutWrapper faders" data-aos="fade-up">
            <img src={aiImg2} alt="ai-img" />
            <div className="aboutDiv">
              <div>
                <div className="aboutTitle">About</div>
                <div className="aboutMainTxtheading">Baby YooshiApe</div>
              </div>
              <div className="aboutMainTxt1">Meet the newest member of the grand YooshiApe family, Baby YooshiApe! This unique token has been designed to empower the YooshiApe community even further. We're offering special benefits for every investor holding Baby YooshiApe. Here's what Baby YooshiApe brings to the table:</div>
              <div className="aboutMainTxt2">💎 YooshiApe Reflection: Baby YooshiApe token holders receive a YooshiApe reflection equivalent to 1% of every transaction fee. Holding Baby YooshiApe will aid in increasing your YooshiApe stacks!</div>
              <div className="aboutMainTxt2">🚀 2X YooshiApe Rewards: The perks of Baby YooshiApe don’t end there. If you also hold a minimum of 250k YooshiApe tokens, your YooshiApe rewards are doubled!</div>
              <div className="aboutMainTxt2">At YooshiApe family, we aim to offer our investors not just a token, but an experience. Double up this experience with Baby YooshiApe!</div>
              <img className="sparkleIcon4" src={sparkleIcon4} alt="sparkle" />
            </div>
          </div>
          <div className="goalWrapper faders" data-aos="fade-up">
            {/* <div className='eclipseDiv'></div> */}
            <div>
              <img className="sparkleIcon5" src={sparkleIcon5} alt="sparkle" />
              <img className="sparkleIcon3" src={sparkleIcon3} alt="sparkle" />
              <div className="aboutTitle">The goal is</div>
              <div className="aboutMainTxtheading">To Ignite a Movement, Amplify our Strength, and Shape an Unstoppable Future!</div>
            </div>
            <div className="aboutMainTxt1">Are you ready for growth, innovation, and moving forward as one? With Baby YooshiApe, we're offering not just a token, but a revolution! With this new member of the YooshiApe family, our aim is to double our community's strength and build an unstoppable ecosystem. We're uniting for a bigger, more connected, and more powerful community. Join us on this journey and carve history with the YooshiApe momentum!</div>

            <img className="sparkleIcon2" src={sparkleIcon2} alt="sparkle" />
          </div>
          <div id="tokenomics" className="tokenomicsWrapper faders" data-aos="fade-up">
            <div className="tokenomics">
              <div className="tokenomicsFeatures">
                <div className="headingUnderlineWrapper">
                  <div className="tokenomicsTxt">Tokenomics</div>
                </div>
                <div className="tokenomics-div">
                  <div>
                    <div className="tokenomics-box">
                      <li>
                        Token Name : <span>Baby YooshiApe</span>
                      </li>
                      <li>
                        Token Symbol: <span>bYooshiApe</span>
                      </li>
                      <li>
                        Total Supply: <span>1B (1,000,000,000)</span>
                      </li>
                      <li>
                        Decimals: <span>18</span>
                      </li>
                    </div>
                    <br />
                    <div className="tokenomics-box">
                      <li>
                        Blockchain: <span>Binance Smart Chain (BEP-20)</span>
                      </li>
                      <li>
                        Tokens for fairlaunch: <span>500m</span>
                      </li>
                      <li>
                        Tokens for liquidity: <span>242m (ratio: 51%)</span>
                      </li>
                      <li>
                        Initial burn: <span>258m</span>
                      </li>
                      <li>
                        Token tax @buy: <span>3% Tax</span>
                      </li>
                      &nbsp;&nbsp;&nbsp;(1% YooshiApe rewards, 2% marketing )
                      <li>
                        Token tax @sell: <span>3% Tax</span>
                      </li>
                      &nbsp;&nbsp;&nbsp;(1% YooshiApe rewards, 2% marketing )
                    </div>
                  </div>
                  <div className="progress-bar-div">
                    <div className="progress-bar-align">
                      <div className="progress-bar-container">
                        <div className="loader">
                          <CircularProgressBar percentage={51} circleWidth={"150"} color="#48c63f" className="loader" />
                          <h1>Fair Launch Tokens</h1>
                        </div>
                      </div>
                      <div className="progress-bar-container">
                        <div className="loader">
                          <CircularProgressBar percentage={25} circleWidth={"150"} color="#48c63f" />
                          <h1>Liquidity</h1>
                        </div>
                      </div>
                      <div className="progress-bar-container">
                        <div className="loader">
                          <CircularProgressBar percentage={24} circleWidth={"150"} color="#48c63f" />
                          <h1>Initial Burn</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
                <br/>
                <div className="aboutMainTxt">
                    💎 YooshiApe Reflection: Baby YooshiApe token holders receive a YooshiApe reflection equivalent to 1% of every transaction fee. Holding Baby YooshiApe will aid in increasing your YooshiApe stacks! <br /><br /> 🚀 2X YooshiApe Rewards: The perks of Baby YooshiApe don’t end there. If you also hold a minimum of 250k YooshiApe tokens, your YooshiApe rewards are doubled!
                  </div>
              </div>
            </div>
          </div>
          {/* <div id="roadmap" className="roadmapdiv faders" data-aos="fade-up">
            <div className="roadmapimg">{isMobile ? <img src={roadmapmob} alt="roadmap" /> : <img src={roadmap} alt="roadmap" />}</div>
          </div> */}
        </div>
      </div>
    </>
  );
}
