let twitterlink = "https://twitter.com/BabyYooshiApe";

let telegramlink = "https://t.me/BabyYooshiApe";

let pinksalelink = "https://www.pinksale.finance/launchpad/0xfdD1da545A505a21656EC2a8a9a8d02fFf5a02a7?chain=BSC";

let pancakeswaplink = "https://pancakeswap.finance/swap?outputCurrency=0x39b548bb66D6E2400B4288b25f4837F55FBeD3E5";

let dexviewlink = "https://www.dexview.com/bsc/0x39b548bb66D6E2400B4288b25f4837F55FBeD3E5";

let bscscanlink = "https://bscscan.com/token/0x39b548bb66d6e2400b4288b25f4837f55fbed3e5";

let coinmarketcaplink = "";

let coinsultlink = "https://coinsult.net/projects/baby-yooshi-ape/";

let tokenAddress = "0x39b548bb66D6E2400B4288b25f4837F55FBeD3E5";

let whitepaperlink = "";

let auditlink = " https://coinsult.net/projects/baby-yooshi-ape/";

let YooshiApelink = "https://yooshiape.com";

export { twitterlink, telegramlink, dexviewlink, bscscanlink, pancakeswaplink, coinmarketcaplink, pinksalelink, coinsultlink, tokenAddress, whitepaperlink, YooshiApelink, auditlink };
